import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { TransactionReceipt } from '@ethersproject/providers'

import { useAppDispatch } from '../app/hooks'
import { HookProvider } from './types'
import { useFetchBlock, useFetchTransactions } from '.'

interface WatchData {
  provider?: HookProvider
  txHash?: string
  confirmations?: number
  onSuccess(receipt: TransactionReceipt, confirmations: number): void
  onError(receipt: TransactionReceipt, confirmations: number): void
}

const MAX_CHECKS = 3

const useWatchTx = ({
  provider, txHash, confirmations,
  onSuccess, onError,
}: WatchData): void => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { data: txs } = useFetchTransactions({
    provider,
    txHashes: (txHash && !confirmations) ? [txHash] : [],
  })
  const { data: block } = useFetchBlock({ provider })

  const receipt = !txs ? null : txs[0]

  useEffect(() => {
    if (!txHash || !receipt) return

    if (!receipt.status || receipt.status === 0) {
      enqueueSnackbar(`Tx "${receipt.transactionHash}" failed!`, { variant: 'warning' })
      onError(receipt, 1)
      return
    }

    if (confirmations && confirmations >= MAX_CHECKS) return

    if (block?.number && block.number >= receipt.blockNumber + 1) {
      onSuccess(receipt, block.number - receipt.blockNumber)
    }
  }, [txHash, confirmations, block?.number, dispatch, receipt, enqueueSnackbar, onSuccess, onError])
}

export default useWatchTx
