import { styled } from '@mui/material/styles'
import { BasePaper } from '.'
import { radialConstructor } from './utils'

const FarmWarningPaper = styled(BasePaper)(({ theme }) => ({
  textAlign: 'left',
  boxShadow: `${(theme.palette.mode === 'light') ? '#33333333' : theme.palette.primary.dark} 0px 4px 21px`,
  background: (theme.palette.mode === 'light')
    ? radialConstructor(theme.palette.primary.main, theme.palette.warning.light, 'ellipse at top')
    : radialConstructor(theme.palette.warning.main, theme.palette.primary.main, 'ellipse at top'),
}))

export default FarmWarningPaper
