import React, { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Typography } from '@mui/material'

import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useWeb3React } from '@web3-react/core'
import {
  Web3Provider,
} from '@ethersproject/providers'

import { BigNumber } from 'ethers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ModalType, openModal } from '../../features/modal/modalSlice'
import { TokenButton } from '../buttons'
import {
  getSelectedDexToken, setDexIndexer, setDexSelectedAmount, TokenIndexer, TokenState,
} from '../../features/dex/dexSlice'
import { AccountBalance } from '../web3'

const InputBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: (theme.palette.mode === 'light') ? '#f5f5f9' : '#393939',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}))

interface IDOInputProps {
  indexer: TokenIndexer
  label?: string
  showMax?: boolean
  maxLimitAmount?: BigNumber
}

const IDOInput = ({
  indexer, showMax, label, maxLimitAmount,
}: IDOInputProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const {
    active, chainId, account, library: provider,
  } = useWeb3React<Web3Provider>()

  const token = useAppSelector(getSelectedDexToken(indexer))

  const { currency, selectedAmount } = token as TokenState

  const handleTokenButton = () => {
    dispatch(setDexIndexer(indexer))
    dispatch(openModal(ModalType.SELECT_DEX_TOKEN))
  }

  const parsedAmount = selectedAmount
    ? +formatUnits(selectedAmount, currency?.decimals)
    : ''

  const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (!currency) return

    let { value } = event.target
    value = +value >= 0 ? value : ''

    if (value.includes('-') || value.length > 32) return

    const splitedV = value.split('.')[1]
    if (splitedV && splitedV.length > currency.decimals) return

    if (!currency) return

    dispatch(setDexSelectedAmount({
      indexer,
      amount: value ? parseUnits(value, currency.decimals) : undefined,
      update: true,
    }))
  }, [dispatch, currency, indexer])

  return (
    <InputBox
      sx={{
        padding: 0, margin: 0, width: '100%',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          display="flex"
          sx={{ justifyContent: 'space-between' }}
        >
          {active && chainId && account && provider && currency ? (
            <AccountBalance
              maxLimitAmount={maxLimitAmount}
              account={account}
              indexer={indexer}
              currency={currency}
              showMax={showMax}
              label={label}
            />
          ) : (
            <Typography
              variant="body2"
              color="secondary"
              noWrap
              sx={{ marginRight: 0.5 }}
            >
              0
            </Typography>
          )}
        </Grid>
        <Grid item xs={8} display="flex">
          <TextField
            fullWidth
            placeholder="0.0"
            InputProps={{
              // patern: '[0-9]*(.[0-9]+)?',
              sx: {
                borderRadius: 0,
                fontSize: 14,
              },
              disableUnderline: (!currency),
            }}
            onChange={onInputChange}
            variant="standard"
            autoComplete="off"
            autoCorrect="off"
            InputLabelProps={{ shrink: false, color: 'secondary' }}
            color="secondary"
            type="number"
            value={parsedAmount}
            disabled={!currency}
          />
        </Grid>
        <Grid item xs={4} display="flex">
          <TokenButton
            disabled
            symbol={currency?.symbol}
            logoURI={currency?.logoURI}
            onClick={handleTokenButton}
            asEmpty
          />
        </Grid>
      </Grid>
    </InputBox>
  )
}

export default IDOInput
