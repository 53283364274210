import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { getProjectInfo } from '../../project'

const { colors } = getProjectInfo()

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: colors[theme.palette.mode].tooltip.color,
    backgroundColor: colors[theme.palette.mode].tooltip.backgroundColor,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${colors[theme.palette.mode].tooltip.borderColor}`,
    borderRadius: theme.spacing(1),
  },
}))

export default HtmlTooltip
