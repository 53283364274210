import ConnectModal from '../components/modals/ConnectModal'
import BridgeTokenModal from '../components/modals/BridgeTokenModal'
import BridgeChainSelectModal from '../components/modals/BridgeChainSelectModal'
import WrongNetworkModal from '../components/modals/WrongNetworkModal'
import BridgeTransferModal from '../components/modals/BridgeTransferModal'
import WalletModal from '../components/modals/WalletModal'
import DexTokenModal from '../components/modals/DexTokenModal'
import DexNetworkModal from '../components/modals/DexNetworkModal'

const ModalBaseline = (): JSX.Element => (
  <>
    <ConnectModal />
    <BridgeTokenModal />
    <BridgeChainSelectModal />
    <BridgeTransferModal />
    <WrongNetworkModal />
    <WalletModal />
    <DexTokenModal />
    <DexNetworkModal />
  </>
)

export default ModalBaseline
