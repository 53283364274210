import { grey } from '@mui/material/colors'

import { DEFAULT_PROJECT_NAME } from './helpers/constants'

import KirkaSvg from './assets/icons/kirka.svg'
import { RedirectHome } from './components/pages'
import { AppRoute } from './helpers/types'

interface ColorModeData {
  primary: {
    light?: string,
    main: string,
    dark?: string,
    contrastText?: string,
  },
  secondary: {
    light?: string,
    main: string,
    dark?: string,
    contrastText?: string,
  },
  tooltip: {
    color: string,
    backgroundColor: string,
    borderColor: string,
  },
}

interface ProjectData {
  pages: AppRoute[]
  infoURL?: string,
  docsURL?: string,
  companyName: string,
  termsURL: string,
  logo: string,
  url: string,
  colors: {
    light: ColorModeData,
    dark: ColorModeData,
    backgroundColor?: string,
    background?: string,
    warningPaper: {
      color: string,
      backgroundColor: string,
    },
  }
}

interface ProjectsData {
  [name: string]: ProjectData
}

const projectData: ProjectsData = {
  kirkaswap: {
    pages: [
      {
        name: 'Home',
        disabled: true,
        path: '/',
        component: RedirectHome,
      },
    ],
    infoURL: 'https://info.kirkaswap.com',
    companyName: 'KirkaSwap',
    termsURL: '',
    logo: KirkaSvg,
    url: '#/',
    colors: {
      light: {
        primary: {
          main: '#a8ff78',
        },
        secondary: {
          main: grey[900],
        },
        tooltip: {
          color: '#000000de',
          backgroundColor: '#f5f5f9',
          borderColor: grey[900],
        },
      },
      dark: {
        primary: {
          main: grey[900],
        },
        secondary: {
          main: '#a8ff78',
        },
        tooltip: {
          color: '#a8ff78',
          backgroundColor: '#1b1b1b',
          borderColor: '#a8ff78',
        },
      },
      backgroundColor: '#a8ff78',
      background: 'linear-gradient(to right, #2C5364, #203A43, #0F2027)',
      warningPaper: {
        color: grey[800],
        backgroundColor: '#a8ff78',
      },
    },
  },
}

export const getProjectInfo = (): ProjectData => (
  projectData[process.env.REACT_APP_PROJECT_NAME || DEFAULT_PROJECT_NAME]
)
