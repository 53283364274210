import LoadingButton from '@mui/lab/LoadingButton'

import { Web3Provider } from '@ethersproject/providers'
import { Currency } from '../../entities'
import useFetchMetamaskAPI from '../../hooks/useFetchMetamaskAPI'

interface AddTokenButtonProps {
  provider?: Web3Provider
  currency: Currency
  text?: string
  fullWidth?: boolean
}

export const AddTokenButton = ({
  provider, currency, text, fullWidth,
}: AddTokenButtonProps): JSX.Element => {
  const { addToken, isLoading } = useFetchMetamaskAPI(provider)
  return (
    <LoadingButton
      color="secondary"
      size="small"
      loading={isLoading}
      onClick={() => addToken(currency)}
      sx={{ height: 30, fontSize: '0.7rem' }}
      style={{ textTransform: 'none' }}
      fullWidth={fullWidth}
    >
      {text || 'Add to wallet'}
    </LoadingButton>
  )
}
