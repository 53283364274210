import { constants, BigNumber, ethers } from 'ethers'

import { Currency, Token, WETH9 } from '../../entities'
import { MAX_PERCENT } from '../../helpers/constants'
import { ChainData } from '../../helpers/types'
import { getChainData } from '../../helpers/utilities'

interface DexPath {
  chain?: ChainData
  weth9?: Token
  path: [string, string]
}

export const getDexPath = (currency0?: Currency, currency1?: Currency): DexPath => {
  // NOTE: Maybe not good to take from first currency in case of bridge?
  const chain = getChainData(currency0?.chainId)

  const weth9 = WETH9[chain?.chainId || 0]
  let path: [string, string] = [constants.AddressZero, constants.AddressZero]
  if (currency0 && currency1) {
    if (currency0.address === constants.AddressZero) {
      path = (weth9?.address.toLowerCase() < currency1.address.toLowerCase())
        ? [weth9?.address, currency1.address]
        : [currency1.address, weth9?.address]
    } else if (currency1.address.toLowerCase() === constants.AddressZero.toLowerCase()) {
      path = (currency0.address < weth9?.address)
        ? [currency0.address, weth9?.address]
        : [weth9?.address, currency0.address]
    } else {
      path = (currency0.address.toLowerCase() < currency1.address.toLowerCase())
        ? [currency0.address, currency1.address]
        : [currency1.address, currency0.address]
    }
  }
  return {
    path,
    chain,
    weth9,
  }
}

// conver sorted path to project currencies
export const pathToBridgeCurrencies = (
  currency0?: Currency,
  currency1?: Currency,
): [Currency, Currency] | undefined => {
  const { path, chain, weth9 } = getDexPath(currency0, currency1)
  if (path[0] === constants.AddressZero || path[1] === constants.AddressZero) return undefined

  if (!weth9 || !chain) return undefined

  if (!currency0 || !currency1) return undefined

  if (path[0] === weth9.address) {
    return (currency1.address === path[1]) ? [currency0, currency1] : [currency1, currency0]
  } if (path[1] === weth9.address) {
    return (currency0.address === path[0]) ? [currency0, currency1] : [currency1, currency0]
  }
  return (currency0.address === path[0]) ? [currency0, currency1] : [currency1, currency0]
}

export enum SlippageType {
  MIN = 1,
  MAX = 2
}

export const calculateAmountWithSlippage = (
  amount: BigNumber,
  slippage: number,
  type: SlippageType = SlippageType.MIN,
): BigNumber => {
  if (type === SlippageType.MIN) {
    return amount.sub(
      amount
        .mul(
          BigNumber.from(slippage),
        )
        .div(BigNumber.from(MAX_PERCENT)),
    )
  }
  return amount.add(
    amount
      .mul(BigNumber.from(slippage))
      .div(BigNumber.from(MAX_PERCENT)),
  )
}

export const PRICE_IMPACT_SEVERITY_WARNING = 3
export const PRICE_IMPACT_SEVERITY_DANGER = 20

export const hasPriceSeverifyWarning = (price: number, severity: number): boolean => {
  if (Math.sign(price) !== -1) return false

  return Math.abs(price) > severity
}

export const isWrapDirection = (currency0?: Currency, currency1?: Currency): boolean => {
  if (!currency0 || !currency1) return false

  const weth9 = WETH9[currency0.chainId]
  if (!weth9) return false

  return Boolean(
    currency0.address.toLowerCase() === weth9.address.toLowerCase()
    && currency1.address.toLowerCase() === ethers.constants.AddressZero,
  )
}

export const isWrapped = (currency?: Currency): boolean => {
  if (!currency) return false
  const weth9 = WETH9[currency.chainId]
  if (!weth9) return false

  return Boolean(weth9.address.toLowerCase() === currency.address.toLowerCase())
}
