import { useState, useEffect, useCallback } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import { orange, grey } from '@mui/material/colors'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'

import { ConnectorNames, connectorsByName } from '../../helpers/connectors'
import { deforceWallet, setConnector } from '../../features/wallet/walletSlice'
import BootstrapDialogTitle from './BootstrapDialogTitle'
import { WalletButton } from '../buttons'
import { getProjectInfo } from '../../project'
import {
  MetaMaskSvg, TokenPocketSvg, WalletConnectSvg, TrustWalletSvg,
} from '../../assets/icons/wallets'

const NotificationPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: (theme.palette.mode === 'light') ? orange[700] : orange[700],
  border: `solid 1px ${orange[800]}`,
  padding: theme.spacing(2),
  color: orange[100],
  '& a': {
    color: orange[100],
    textDecorationColor: orange[100],
  },
  '& a:hover': {
    color: grey[800],
    textDecorationColor: grey[800],
  },
}))

const projectInfo = getProjectInfo()

const walletButtons = [
  {
    name: 'MetaMask',
    src: MetaMaskSvg,
    connector: ConnectorNames.Injected,
  },
  {
    name: 'Trust Wallet',
    src: TrustWalletSvg,
    connector: ConnectorNames.Injected,
  },
  {
    name: 'TokenPocket',
    src: TokenPocketSvg,
    connector: ConnectorNames.Injected,
  },
  // NOTE: Have bugs with adding chain + wrong tx creation
  // {
  //   name: 'Coinbase Wallet',
  //   src: CoinbaseWalletSvg,
  //   connector: ConnectorNames.WalletLink,
  // },
  // NOTE: Supports only BSC chains, could not switch chain
  // {
  //   name: 'Binance Wallet',
  //   src: BinanceWalletSvg,
  //   connector: ConnectorNames.BSC,
  // },
  {
    name: 'WalletConnect',
    src: WalletConnectSvg,
    connector: ConnectorNames.WalletConnect,
  },
]

const ConnectModal = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.CONNECT])

  const [connection, setConnection] = useState<{
    loading: boolean,
    connector: ConnectorNames | null,
  }>({
    loading: false,
    connector: null,
  })
  const { account, error, activate } = useWeb3React<Web3Provider>()

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch])

  useEffect(() => {
    if (error && connection.loading) {
      setConnection({
        ...connection,
        loading: false,
      })
    }
  }, [connection, error, setConnection])

  useEffect(() => {
    if (account && connection.loading) {
      dispatch(setConnector(connection.connector))
      setConnection({
        connector: null,
        loading: false,
      })
      handleClose()
    }
  }, [dispatch, connection, account, setConnection, handleClose])

  const onConnect = (type: ConnectorNames) => async () => {
    setConnection({
      loading: true,
      connector: type,
    })
    dispatch(deforceWallet())
    await activate(connectorsByName[type])
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="connect-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <BootstrapDialogTitle id="connect-dialog-title" onClose={handleClose}>
        Connect a wallet
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <List>
          <ListItem>
            <NotificationPaper variant="outlined">
              <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                {`By connecting a wallet, you agree to ${projectInfo.companyName}’`}
                {' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={projectInfo.termsURL}
                >
                  Terms of Service
                </Link>
              </Typography>
            </NotificationPaper>
          </ListItem>
          {connection.loading ? (
            <ListItem>
              <LoadingButton
                loading
                variant="outlined"
                color="secondary"
                fullWidth
                sx={{
                  textTransform: 'none',
                  justifyContent: 'space-between',
                  padding: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                size="large"
              >
                Initializing...
              </LoadingButton>
            </ListItem>
          ) : (
            // here will go other connections
            <>
              {walletButtons.map((walletButton) => (
                <ListItem key={walletButton.name}>
                  <WalletButton
                    name={walletButton.name}
                    src={walletButton.src}
                    onClick={onConnect(walletButton.connector)}
                  />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default ConnectModal
