import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  persistReducer, createMigrate, MigrationManifest,
} from 'redux-persist'

import { RootState } from '../../app/store'
import { createStorage } from '../../helpers/utilities'

interface BridgeConfig {
  useExperimental: boolean
  safeMax: boolean
}

interface DexConfig {
  slippage: number // 100 means 1 percent
  deadline: number // minutes
}

interface ThemeConfig {
  isDark: boolean
}

interface SettingsState {
  bridge: BridgeConfig
  dex: DexConfig
  theme: ThemeConfig
}

const initialState: SettingsState = {
  bridge: {
    useExperimental: true,
    safeMax: true,
  },
  dex: {
    slippage: 50,
    deadline: 1,
  },
  theme: {
    isDark: true,
  },
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateBridgeSettings: (state, action: PayloadAction<BridgeConfig>) => {
      state.bridge = action.payload
    },
    updateDexSettings: (state, action: PayloadAction<DexConfig>) => {
      state.dex = action.payload
    },
    updateThemeSettings: (state, action: PayloadAction<ThemeConfig>) => {
      state.theme = action.payload
    },
  },
})

export const getBridgeSettings = (state: RootState): BridgeConfig => state.settings.bridge
export const getDexSettings = (state: RootState): DexConfig => state.settings.dex
export const getThemeSettings = (state: RootState): ThemeConfig => state.settings.theme

export const {
  updateBridgeSettings,
  updateDexSettings,
  updateThemeSettings,
} = settingsSlice.actions

const migrations: MigrationManifest = {
  2: (state) => {
    (state as unknown as SettingsState).dex.slippage = 50
    return state
  },
}

export default persistReducer({
  key: settingsSlice.name,
  version: 2,
  storage: createStorage('kirkaDB'),
  migrate: createMigrate(migrations, { debug: true }),
}, settingsSlice.reducer)
