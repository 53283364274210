import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'

const SettingsCheckbox = styled(Checkbox)(({ theme }) => ({
  color: (theme.palette.mode === 'light') ? '#2b2b2b' : theme.palette.secondary.main,
  '&.Mui-checked': {
    color: (theme.palette.mode === 'light') ? '#2b2b2b' : theme.palette.secondary.main,
  },
}))

export default SettingsCheckbox
