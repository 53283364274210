import React from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/material/styles'

import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import {
  Web3Provider,
} from '@ethersproject/providers'

import { getProjectInfo } from '../../project'
import { ImgIcon } from '../icons'
import {
  getChainData,
  getDumbLogoURI, getPrettyDate, prettyFormat,
} from '../../helpers/utilities'
import { SocialLink } from '../links'
import { IDOPool, useFetchIDOInfo } from '../../hooks/launchpad'
import { radialConstructor } from './utils'
import { useAppSelector } from '../../app/hooks'
import { VestingButton } from '../buttons'

const VideoFrame = styled('iframe')(({ theme }) => ({
  width: 560,
  height: 315,
  borderRadius: theme.spacing(1),
}))

const projectInfo = getProjectInfo()

interface InfoFactoryBoxProps {
  title: string
  items: ({
    label: string
    description: string | JSX.Element
  } | null | undefined)[]
}

const InfoFactoryBox = ({
  title,
  items,
}: InfoFactoryBoxProps): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'column',
      padding: 4,
      paddingTop: 0,
    }}
  >
    <Typography variant="h6" color="secondary" sx={{ marginBottom: 2 }}>{title}</Typography>
    {items.map((item) => {
      if (!item) return null

      return (
        <Box
          key={item.label}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
          pb={0.5}
        >
          <Typography variant="subtitle2" sx={{ marginRight: 1 }}>{`${item.label}:`}</Typography>
          {React.isValidElement(item.description) ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {item.description}
            </>
          ) : (
            <Typography variant="subtitle2" color="secondary">
              {item.description}
            </Typography>
          )}
        </Box>
      )
    })}
  </Box>
)

interface IDOInfoPaperProps {
  pool: IDOPool
}

const IDOInfoPaper = ({ pool }: IDOInfoPaperProps): JSX.Element => {
  const { account } = useAppSelector((state) => state.wallet)
  const {
    description,
    socials,
    sellToken,
    buyToken,
    decimals,
    timeline,
    tokenAddress,
    demoURL,
    vestingDescription,
    isCanceled,
  } = pool

  const {
    totalRaise,
    rate,
    reversePoolRate,
    initialSupply,
    totalSupply,
    marketCap,
  } = pool.amounts

  const chain = getChainData(pool.chainId)

  const {
    chainId, library: provider,
  } = useWeb3React<Web3Provider>()

  const { data: idoInfoResult, isLoading } = useFetchIDOInfo({
    provider,
    chainId: pool.chainId,
    contract: pool.address,
    account,
  })

  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        background: (theme.palette.mode === 'light')
          ? radialConstructor('#ffffff', theme.palette.primary.main)
          : radialConstructor(theme.palette.primary.light, theme.palette.primary.dark),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'unset',
          justifyContent: 'space-between',
        }}
        pt={4}
        pl={4}
        pr={4}
        pb={2}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <ImgIcon
            src={buyToken.logoURI || getDumbLogoURI()}
            height={36}
            width="auto"
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginLeft: 1,
            }}
          >
            {buyToken.name}
          </Typography>
        </Box>
        {isCanceled && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              variant="h6"
              color="error"
              sx={{
                marginLeft: 1,
              }}
            >
              CANCELED
            </Typography>
          </Box>
        )}
      </Box>
      {description && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'unset',
            justifyContent: 'space-between',
            padding: 4,
            paddingTop: 0,
            paddingBottom: 2,
          }}
        >
          <Typography variant="body2" sx={{ opacity: '.7' }}>
            {description}
          </Typography>
        </Box>
      )}
      {socials && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flext-start',
            flexWrap: 'wrap',
            width: '75hv',
            paddingLeft: 4,
            paddingRight: 4,
            paddingBottom: 2,
          }}
        >
          {socials.map((socialLink) => (
            <SocialLink key={`${socialLink.slice(0, 20)}`} url={socialLink} />
          ))}
        </Box>
      )}
      {demoURL && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flext-start',
            flexWrap: 'wrap',
            width: '100hv',
            paddingLeft: 4,
            paddingRight: 4,
            paddingBottom: 2,
          }}
        >
          <VideoFrame
            id="video"
            title="Demo link"
            src={demoURL}
            frameBorder="0"
            allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      )}
      <InfoFactoryBox
        title="Pool details"
        items={[
          {
            label: 'Hard cap',
            description: `${prettyFormat(totalRaise, decimals, 2).toLocaleString()} ${sellToken.symbol}`,
          },
          {
            label: 'Swap rate',
            description: `1 ${sellToken?.symbol} = ${prettyFormat(rate, 6, 6)} ${buyToken.symbol}`
              + ` | ${prettyFormat(reversePoolRate, 6, 6)} `
              + `${sellToken.symbol} per 1 ${buyToken.symbol}`,
          },
          {
            label: 'Start/end',
            description: (timeline.startTime && timeline.endTime)
              ? `${getPrettyDate(timeline.startTime, true, true)} - `
              + `${getPrettyDate(timeline.endTime, true)}` : 'TBA',
          },
          {
            label: 'Token listing',
            description: (timeline?.listingTime) ? getPrettyDate(timeline.listingTime) : 'TBA',
          },
        ]}
      />
      <InfoFactoryBox
        title="Price"
        items={[
          {
            label: 'Listing',
            description: `${prettyFormat(reversePoolRate, 6, 6)} ${sellToken.symbol}`,
          },
        ]}
      />
      <InfoFactoryBox
        title="Token"
        items={[
          {
            label: 'Token',
            description: buyToken.name,
          },
          {
            label: 'Type',
            description: 'GWC-20',
          },
          {
            label: 'Total supply',
            description: `${prettyFormat(totalSupply, decimals, 0).toLocaleString()} `
              + `${buyToken.symbol}`,
          },
          {
            label: 'Initial supply',
            description: `${prettyFormat(initialSupply, decimals, 0).toLocaleString()} `
              + `${buyToken.symbol}, market cap ${prettyFormat(marketCap, decimals, 0).toLocaleString()} `
              + `${sellToken.symbol}`,
          },
          {
            label: 'Address',
            description: (tokenAddress && tokenAddress !== ethers.constants.AddressZero) ? (
              <Link
                color="secondary"
                target="_blank"
                rel="noopener noreferrer"
                href={`${chain?.explorerURL}/token/${tokenAddress}`}
              >
                {tokenAddress}
              </Link>
            ) : (
              'TBA'
            ),
          },
        ]}
      />
      <InfoFactoryBox
        title="Distribution"
        items={[
          {
            label: 'Distribution',
            description: `Claimed on ${projectInfo.companyName}`,
          },
          {
            label: 'Vesting',
            description: vestingDescription || 'TBA',
          },
        ]}
      />
      <Box
        sx={{
          padding: 4,
          paddingTop: 0,
          paddingBottom: 4,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="secondary">Date</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="secondary">%</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" color="secondary">Amount</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2" color="secondary">Claimed</Typography>
          </Grid>
          <Grid item xs={3}>
            {chainId !== pool.chainId && (
              <Button
                variant="outlined"
                color="secondary"
                disabled
                sx={{
                  fontSize: 10,
                  height: 24,
                }}
              >
                Connect wallet first
              </Button>
            )}
          </Grid>
          {chainId !== pool.chainId || isLoading ? (
            <Grid item xs={12}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Grid>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!isCanceled && idoInfoResult?.claims?.map((claim) => (
                <React.Fragment key={claim.idx}>
                  <Grid item xs={3}>
                    <Typography variant="body2">{getPrettyDate(claim.time, true)}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2">{`${prettyFormat(claim.percent, 6, 2)}%`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">{prettyFormat(claim.amount, decimals, 6)}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2">
                      {(!claim.claimedAmount.eq(ethers.constants.Zero))
                        ? <CheckCircleIcon sx={{ width: 18, color: 'success.main' }} />
                        : <CancelIcon sx={{ width: 18, color: 'secondary.main' }} />}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <VestingButton
                      variant="outlined"
                      contract={pool.address}
                      vesting={claim}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  )
}

export default IDOInfoPaper
