import { memo } from 'react'

import { SxProps, Theme } from '@mui/material/styles'

import {
  TelegramIcon,
  LinkedInIcon,
  TwitterIcon,
  GitIcon,
  MediumIcon,
  DiscordIcon,
  YouTubeIcon,
  EmailIcon,
  GitbookIcon,
  InstaIcon,
  WebIcon,
} from '../../assets/icons/social'

import { ImgIcon } from '.'

enum SocialType {
  EMAIL = 1,
  GITBOOK,
  TELEGRAM,
  LINKEDIN,
  YOUTUBE,
  DISCORD,
  GITHUB,
  TWITTER,
  MEDIUM,
  INSTA,
  WEB,
}

const socialMap = {
  [SocialType.EMAIL]: EmailIcon,
  [SocialType.GITBOOK]: GitbookIcon,
  [SocialType.TELEGRAM]: TelegramIcon,
  [SocialType.LINKEDIN]: LinkedInIcon,
  [SocialType.YOUTUBE]: YouTubeIcon,
  [SocialType.GITHUB]: GitIcon,
  [SocialType.DISCORD]: DiscordIcon,
  [SocialType.TWITTER]: TwitterIcon,
  [SocialType.MEDIUM]: MediumIcon,
  [SocialType.INSTA]: InstaIcon,
  [SocialType.WEB]: WebIcon,
}

const getSocialTypeFromURL = (url: string): SocialType => {
  if (url.includes('mailto:')) {
    return SocialType.EMAIL
  }
  if (url.includes('t.me')) {
    return SocialType.TELEGRAM
  }
  if (url.includes('linkedin')) {
    return SocialType.LINKEDIN
  }
  if (url.includes('youtube')) {
    return SocialType.YOUTUBE
  }
  if (url.includes('github')) {
    return SocialType.GITHUB
  }
  if (url.includes('discord')) {
    return SocialType.DISCORD
  }
  if (url.includes('twitter')) {
    return SocialType.TWITTER
  }
  if (url.includes('medium')) {
    return SocialType.MEDIUM
  }
  if (url.includes('instagram')) {
    return SocialType.INSTA
  }
  return SocialType.WEB
}

interface SocialIconProps {
  url: string
  height?: number | string
  width?: number | string
  sx?: SxProps<Theme>
}

const SocialIcon = memo(({
  url, ...restImgProps
}: SocialIconProps): JSX.Element => {
  const type = getSocialTypeFromURL(url)
  const src = socialMap[type]
  return <ImgIcon src={src} alt="Icon" {...restImgProps} />
})

SocialIcon.displayName = 'SocialIcon'

export default SocialIcon
