import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const PageBox = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // minHeight: 'calc(100vh - 100px)',
  // minHeight: 'calc(40vh + 100px)',
  paddingTop: theme.spacing(4),
  flexDirection: 'column',
}))
