import { useCallback, useMemo } from 'react'
import { ethers } from 'ethers'
import { Token, Currency } from '../entities'
import { useFetchTokensQuery } from '../features/api/tokenListApiSlice'
import { DEFAULT_TOKEN_SYMBOL } from '../helpers/constants'
import { getNativeCurrency, matchToken } from './utils'

interface BridgeCurrencyAction {
  getDefaultCurrency(
    chainId: number,
  ): Currency | undefined
  getCurrency(
    chainId?: number,
    addressOrSymbol?: string,
  ): Currency | undefined
  dexCurrencies: Currency[]
  isLoading: boolean
}

const useFetchDexCurrencies = (tokeLists: string[] = []): BridgeCurrencyAction => {
  const { data: tokens = [], isLoading } = useFetchTokensQuery(tokeLists)

  const dexCurrencies = useMemo((): Currency[] => {
    if (isLoading) return []

    const currencies: Token[] = []

    const updateOrCreateToken = (dexToken: Token) => {
      const index = currencies.findIndex((currency) => matchToken(currency, dexToken.address, dexToken.chainId))
      if (index === -1) {
        currencies.push(dexToken)
      }
    }

    function getFromCache(chainId: number, address: string): Token | undefined {
      const crs = currencies.filter((currency) => matchToken(currency, address, chainId))
      if (crs.length) {
        return crs[0]
      }
      return undefined
    }

    // generate default native token
    tokens
      .reduce((store, token) => {
        store.add(token.chainId)
        return store
      }, new Set<number>())
      .forEach((chainId) => {
        const nativeToken: Currency | undefined = getNativeCurrency(+chainId)
        if (!nativeToken) {
          console.log(`Native token for chain "${chainId}" not found`)
          return undefined
        }
        updateOrCreateToken(nativeToken as unknown as Token)
        return undefined
      })

    tokens.forEach((token) => {
      const dexToken = getFromCache(token.chainId, token.address) || new Token(
        token.chainId,
        token.address,
        token.decimals,
        token.symbol,
        token.name,
        token.logoURI,
      )
      updateOrCreateToken(dexToken)
    })
    return currencies
  }, [tokens, isLoading])

  const getCurrency = useCallback((
    chainId?: number,
    addressOrSymbol: string = ethers.constants.AddressZero,
  ): Currency | undefined => dexCurrencies.filter(
    (token) => matchToken(token, addressOrSymbol, chainId),
  )[0], [dexCurrencies])

  const getDefaultCurrency = useCallback((
    chainId: number,
  ): Currency | undefined => dexCurrencies.filter(
    (currency) => currency.chainId === chainId && (
      currency.symbol.includes(DEFAULT_TOKEN_SYMBOL)
    ),
  )[0], [dexCurrencies])

  return {
    getDefaultCurrency,
    getCurrency,
    dexCurrencies,
    isLoading,
  }
}

export default useFetchDexCurrencies
