import { useMemo } from 'react'

import { parseUnits } from '@ethersproject/units'
import { getNowTs } from '../../helpers/utilities'
import { useGraphRequestQuery } from '../../features/api/graphNodeApiSlice'
import { GET_PROJECTS, Project } from '../../graphs/launchpad'
import { ADMIN_ORCHESTRATOR_GRAPH_URI } from '../../helpers/constants'
import { Token } from '../../entities'
import { IDOPool, IDOFilter } from './types'

interface FetchIDOPoolRequest {
  chainId?: number
  filter?: IDOFilter
  address?: string
}

interface FetchIDOPoolResponse {
  getIDOPool(chainId: number, address: string): IDOPool | undefined
  pools: IDOPool[]
  count: {
    upcomming: number
    ended: number
    canceled: number
  }
  isLoading: boolean
  isFetching: boolean
}

const isUpcomming = (pool: IDOPool, now: number): boolean => Boolean(
  (
    pool.timeline.startTime > now
    || (
      pool.timeline.endTime > now
      && pool.timeline.startTime <= now
    )
  ),
)

const isEnded = (pool: IDOPool, now: number): boolean => Boolean(
  pool.timeline
  && (pool.timeline.endTime <= now),
)

const applyFilter = (pool: IDOPool, filter: IDOFilter): boolean => {
  const now = getNowTs()
  if (filter === 'upcomming') {
    return isUpcomming(pool, now)
  }
  if (filter === 'ended') {
    return isEnded(pool, now)
  }
  if (filter === 'canceled') {
    return pool.isCanceled
  }
  if (filter === 'skip') {
    return true
  }
  return false
}

const useFetchIDOPools = ({
  chainId, address, filter = 'upcomming',
}: FetchIDOPoolRequest): FetchIDOPoolResponse => {
  const { data, isLoading, isFetching } = useGraphRequestQuery({
    graphNodeURL: ADMIN_ORCHESTRATOR_GRAPH_URI,
    query: GET_PROJECTS(),
  }, { pollingInterval: 15_000 })

  const idoPools = useMemo(() => {
    const res = data as { projects: Project[] | null }
    // eslint-disable-next-line no-nested-ternary
    const projects = (res) ? (res.projects ? (res.projects || null) : null) : null
    return projects?.map((project) => {
      const totalSupply = parseUnits(project.totalSupply, project.decimals)
      const hardCap = parseUnits(project.hardCap, project.decimals)
      const maxPerUser = parseUnits(project.maxSellLimit, project.decimals)
      const rate = parseUnits(project.rate, 6)
      const divFactor = parseUnits('1', 6)
      const maxPerUserInSell = maxPerUser
        .mul(divFactor)
        .div(rate)

      const totalRaise = hardCap
        .mul(divFactor)
        .div(rate)

      const reversePoolRate = parseUnits('1', 6)
        .mul(divFactor)
        .div(rate)

      const initialSupply = parseUnits(project.initialSupply, project.decimals)
      const marketCap = initialSupply
        .mul(reversePoolRate)
        .div(parseUnits('1', project.fundToken.decimals))
      return {
        id: project.id,
        address: project.address,
        decimals: project.decimals,
        tokenAddress: project.tokenAddress,
        description: project.description,
        chainId: +project.chainId,
        sellToken: new Token(
          +project.fundToken.chainId,
          project.fundToken.address,
          project.fundToken.decimals,
          project.fundToken.symbol,
          project.fundToken.name,
          project.fundToken.logoUri || undefined,
        ),
        buyToken: new Token(
          +project.chainId,
          project.address,
          project.decimals,
          project.symbol,
          project.name,
          project.logoUri || undefined,
        ),
        timeline: {
          startTime: project.startTime,
          endTime: project.endTime,
          listingTime: project.listingTime,
        },
        amounts: {
          hardCap,
          maxPerUser,
          maxPerUserInSell,
          totalRaise,
          rate,
          reversePoolRate,
          initialSupply,
          totalSupply,
          marketCap,
          tokensSold: parseUnits(project.tokensSold, project.decimals),
        },
        hasKYC: project.isWhitelisted,
        socials: project.socials,
        demoURL: project.demoUrl,
        vestingDescription: project.vestingDescription,
        isCanceled: project.isCanceled,
      } as IDOPool
    }) || []
  }, [data])

  const now = getNowTs()

  const chainPools = useMemo(
    () => ((chainId)
      ? idoPools.filter((pool) => pool.chainId === chainId && applyFilter(pool, filter))
      : idoPools.filter((pool) => applyFilter(pool, filter))),
    [chainId, idoPools, filter],
  )

  const count = useMemo(
    () => idoPools.reduce((countData, pool) => {
      if (chainId && pool.chainId !== chainId) return countData
      if (isUpcomming(pool, now)) {
        countData.upcomming += 1
      } else if (isEnded(pool, now)) {
        countData.ended += 1
      } else if (pool.isCanceled) {
        countData.canceled += 1
      }
      return countData
    }, { upcomming: 0, ended: 0, canceled: 0 }),
    [chainId, now, idoPools],
  )

  const getIDOPool = (pChainId: number, pAddress: string): IDOPool | undefined => chainPools.find(
    (pool) => pool.chainId === pChainId && pool.address === pAddress,
  )

  return {
    getIDOPool,
    pools: chainPools.filter((pool) => (address ? pool.address === address : true)),
    count,
    isFetching,
    isLoading,
  }
}

export default useFetchIDOPools
