import { useCallback, useMemo, useEffect } from 'react'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { BigNumber, ethers } from 'ethers'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { parseUnits } from '@ethersproject/units'
import { ImgIcon } from '../icons'
import {
  getChainData, getDumbLogoURI, getNowTs, getPrettyDate, prettyFormat,
} from '../../helpers/utilities'
import { BuyButton, SmartConnectorWrapper } from '../buttons'
import { IDOProgressBar } from '../bars'
import { IDOInput } from '../inputs'
import { IDOPool } from '../../hooks/launchpad'
import { useGraphRequestQuery, useLazyGraphRequestQuery } from '../../features/api/graphNodeApiSlice'
import { ADMIN_ORCHESTRATOR_GRAPH_URI } from '../../helpers/constants'
import {
  CREATE_OR_UPDATE_KYC, GET_WALLET, KYCResponse, WalletResponse,
} from '../../graphs/launchpad'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useFetchBalancePerCurrencies, useFetchMetamaskAPI } from '../../hooks'
import {
  resetDexAmounts, setDexCurrency, setExtraData, TokenIndexer,
} from '../../features/dex/dexSlice'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import IWithLimitsABI from '../../abi/IWithLimits.abi.json'

const InputBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: (theme.palette.mode === 'light') ? '#f5f5f9' : '#393939',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}))

interface TimeBlockProps {
  label: string
  icon: JSX.Element
  startTime?: number
  endTime?: number
}

const TimeBlock = ({
  icon, label, startTime, endTime,
}: TimeBlockProps): JSX.Element => (
  <Grid
    container
    p={2}
    pt={0.5}
    pb={0.5}
  >
    <Grid
      item
      xs={(startTime ? 4 : 12)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        {icon}
        <Typography variant="body2" sx={{ marginLeft: 1 }}>
          {label}
        </Typography>
      </Box>
    </Grid>
    {!!startTime && (
      <Grid
        item
        xs={8}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
          ml={2}
        >
          {endTime ? (
            <Typography variant="body2">
              {`${getPrettyDate(startTime, true, true)} - `
                + `${getPrettyDate(endTime, true)}`}
            </Typography>
          ) : (
            <Typography variant="body2">
              {getPrettyDate(startTime, true)}
            </Typography>
          )}
        </Box>
      </Grid>
    )}
  </Grid>
)

interface IDOBuyFormProps {
  pool: IDOPool
}

const IDOBuyForm: React.FC<IDOBuyFormProps> = ({ pool }) => {
  const dispatch = useAppDispatch()
  const { account } = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()

  useEffect(() => {
    dispatch(resetDexAmounts())
    dispatch(setExtraData(pool))
    dispatch(setDexCurrency({
      indexer: TokenIndexer.TOKEN0,
      currency: pool.sellToken,
    }))
    dispatch(setDexCurrency({
      indexer: TokenIndexer.TOKEN1,
      currency: pool.buyToken,
    }))
  }, [dispatch, pool])

  const { data: limits, isLoading: isLimitsLoading } = useWeb3RequestQuery({
    provider,
    request: {
      address: pool.buyToken.address,
      abi: IWithLimitsABI,
      method: 'getMinMaxLimits',
      params: [],
    },
  })

  const [, maxSell] = (limits)
    ? (limits as [BigNumber, BigNumber])
    : [ethers.constants.Zero, ethers.constants.Zero]

  const {
    data,
  } = useFetchBalancePerCurrencies({
    provider,
    fetchInfo: {
      address: account,
      currency: pool.buyToken,
    },
    pollingInterval: 5_000,
  })

  const balance = useMemo(() => {
    const amount = Array.isArray(data) ? data[0] : data
    return amount || ethers.constants.Zero
  }, [data])

  return (
    <InputBox
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isLimitsLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            {`Purchased: ${prettyFormat(balance, pool.buyToken.decimals)}`}
          </Typography>
          <IDOInput indexer={TokenIndexer.TOKEN0} label="Balance:" />
          <Box mt={4}>
            <ArrowDownwardIcon sx={{ fontSize: 18 }} />
          </Box>
          <IDOInput
            indexer={TokenIndexer.TOKEN1}
            label="Max:"
            showMax
            maxLimitAmount={
              maxSell
                .mul(pool.amounts.rate)
                .div(parseUnits('1', 6))
                .mul(parseUnits('1', pool.decimals - pool.sellToken.decimals))
            }
          />
          <BuyButton
            color="secondary"
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              marginTop: 1,
            }}
          />
        </>
      )}
    </InputBox>
  )
}

interface IDOParticipatePaperProps {
  pool: IDOPool
}

const IDOParticipatePaper = ({ pool }: IDOParticipatePaperProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()
  const currentChain = getChainData(chainId)
  const poolChain = getChainData(pool.chainId)

  const {
    sellToken,
    buyToken,
    timeline,
    hasKYC,
    decimals,
    isCanceled,
  } = pool

  const {
    hardCap,
    rate,
    reversePoolRate,
    tokensSold,
  } = pool.amounts

  const { signMessage, isLoading } = useFetchMetamaskAPI(provider)

  const { data, isLoading: isWalletLoading } = useGraphRequestQuery({
    graphNodeURL: ADMIN_ORCHESTRATOR_GRAPH_URI,
    query: GET_WALLET(account),
  }, { skip: account === ethers.constants.AddressZero, pollingInterval: 10_000 })

  const [createOrUpdateKYC, kycInfo] = useLazyGraphRequestQuery()

  const res = data as { wallet: WalletResponse | null }
  // eslint-disable-next-line no-nested-ternary
  const wallet = (res) ? (res.wallet || null) : null

  useEffect(() => {
    if (!kycInfo.isSuccess) return

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const kyc: KYCResponse | undefined = (kycInfo.data as any)?.createOrUpdateKyc?.kyc
    if (kyc) {
      // const w = window.open(kyc.formUrl, '_blank', 'noopener noreferrer')
      // if (!w) {
      window.location.href = kyc.formUrl
      // }
    }
  }, [kycInfo.isSuccess, kycInfo.data])

  useEffect(() => {
    dispatch(resetDexAmounts())
    dispatch(setExtraData(pool))
    dispatch(setDexCurrency({
      indexer: TokenIndexer.TOKEN0,
      currency: pool.sellToken,
    }))
    dispatch(setDexCurrency({
      indexer: TokenIndexer.TOKEN1,
      currency: pool.buyToken,
    }))
  }, [dispatch, pool])

  const handleKYC = useCallback(async () => {
    const signature = await signMessage(`I would like to sign with ${account.toLowerCase()} for KYC check`, account)
    if (!signature) return

    createOrUpdateKYC({
      graphNodeURL: ADMIN_ORCHESTRATOR_GRAPH_URI,
      query: CREATE_OR_UPDATE_KYC(account, signature),
    }, false)
  }, [account, signMessage, createOrUpdateKYC])

  const actionComponent = useMemo(() => {
    const now = getNowTs()
    const kycFailed = wallet?.kyc?.verificationStatus === 4
    const kycPending = !wallet?.kyc?.isWhitelisted
    if (hasKYC && (!wallet?.kyc || kycFailed)) {
      return (
        <InputBox
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              {!kycFailed ? 'This IDO requires KYC verification.' : 'KYC verification failed, please proceed again'}
            </Typography>
            <LoadingButton color="secondary" variant="outlined" onClick={handleKYC} loading={isLoading}>
              Verify KYC
            </LoadingButton>
          </Box>
        </InputBox>
      )
    }
    if (hasKYC && kycPending && !kycFailed) {
      return (
        <InputBox
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
              KYC:
            </Typography>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={wallet?.kyc?.formUrl}
              color="secondary"
            >
              In review
            </Link>
          </Box>
        </InputBox>
      )
    }
    if (currentChain && poolChain && poolChain.chainId !== currentChain.chainId) {
      return (
        <InputBox
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle2"
            textAlign="center"
          >
            {`Wrong chain. Please switch on ${poolChain.name} to proceed`}
          </Typography>
        </InputBox>
      )
    }
    if (isCanceled) {
      return (
        <InputBox
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle2"
            textAlign="center"
          >
            The sale is canceled
          </Typography>
        </InputBox>
      )
    }
    if (timeline.startTime <= now && timeline.endTime >= now) {
      return <IDOBuyForm pool={pool} />
    }
    if (timeline.endTime < now) {
      return (
        <InputBox
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle2"
            textAlign="center"
          >
            The sale is finished
          </Typography>
        </InputBox>
      )
    }
    if (timeline.startTime > now) {
      return (
        <InputBox
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle2"
            textAlign="center"
          >
            The sale will start soon
          </Typography>
        </InputBox>
      )
    }
    return null
  }, [hasKYC, wallet, poolChain, currentChain, pool, timeline, isLoading, isCanceled, handleKYC])

  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        background: (theme.palette.mode === 'light') ? 'inherit' : theme.palette.primary.dark,
        position: 'sticky',
        top: theme.spacing(10),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'unset',
          justifyContent: 'space-between',
          padding: 2,
        }}
      >
        <ImgIcon
          src={buyToken.logoURI || getDumbLogoURI()}
          height={48}
          width="auto"
          sx={(theme) => ({
            backgroundColor: (theme.palette.mode === 'light') ? 'white' : 'inherit',
            p: 0.5,
          })}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
          mr={2}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            {buyToken.name}
          </Typography>
          <Typography variant="body2" sx={{ opacity: '.7' }}>{`${buyToken.symbol} / ${sellToken.symbol}`}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
          padding: 0,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {isWalletLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <SmartConnectorWrapper
            variant="outlined"
            size="medium"
            color="secondary"
            customProps
            checkDex
          // TODO: Implment this availableChains
          >
            {actionComponent}
          </SmartConnectorWrapper>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
        p={2}
        pb={0}
      >
        <Typography variant="subtitle2" color="secondary">
          {`1 ${sellToken?.symbol} = ${prettyFormat(rate, 6, 6)} ${buyToken.symbol}`}
        </Typography>
        <Typography variant="subtitle2" color="secondary">
          {`1 ${buyToken.symbol} = ${prettyFormat(reversePoolRate, 6, 6)} ${sellToken.symbol}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        p={2}
        pt={0}
        pb={0}
      >
        <IDOProgressBar
          startTime={timeline.startTime}
          endTime={timeline.endTime}
          current={tokensSold}
          total={hardCap}
          rate={rate}
          decimals={decimals}
          buyToken={buyToken}
          sellToken={sellToken}
          color="secondary"
        />
      </Box>
      <TimeBlock
        label={`IDO and distribution on ${poolChain?.shortName.toUpperCase()} chain`}
        icon={<AccountTreeIcon />}
      />
      {timeline && timeline.startTime > 0 && (
        <TimeBlock
          label="Sale"
          icon={<AttachMoneyIcon />}
          startTime={timeline.startTime}
          endTime={timeline.endTime}
        />
      )}
      <Box p={1} />
    </Paper>
  )
}

export default IDOParticipatePaper
