import { Token } from './token'

/**
 * Known WETH9 implementation addresses, used in our implementation of Ether#wrapped
 */
export const WETH9: { [chainId: number]: Token } = {
  // ethereum and testnets
  1: new Token(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'WETH9', 'Wrapped Ether'),
  4: new Token(4, '0xc778417e063141139fce010982780140aa0cd5ab', 18, 'WETH9', 'Wrapped Ether'),
  // godwoken and testnets
  // TODO
  71402: new Token(71402, '0x0000000000000000000000000000000000000000', 18, 'WPCKB', 'Wrapped pCKB'),
  71401: new Token(71401, '0x5BD2e2864EA2A338da5f4b29fE62cF26109A3Af4', 18, 'WpCKB', 'Wrapped pCKB'),
  // binance and testnets
  56: new Token(56, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
  97: new Token(97, '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', 18, 'WBNB', 'Wrapped BNB'),
}
