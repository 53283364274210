import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

interface ButtonProps {
  name: string
  src: string
  onClick?(): void
}

export default ({ name, src, onClick }: ButtonProps): JSX.Element => (
  <Button
    variant="outlined"
    color="secondary"
    fullWidth
    sx={{
      textTransform: 'none',
      justifyContent: 'space-between',
      p: 1,
      paddingLeft: 2,
      paddingRight: 2,
    }}
    size="large"
    onClick={onClick}
  >
    <Typography>{name}</Typography>
    <img src={src} style={{ height: '20px' }} alt={name} />
  </Button>
)
