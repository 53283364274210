import {
  ReactNode, useEffect, useMemo,
} from 'react'

import { createWeb3ReactRoot, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { multipleConnector } from '../helpers/connectors'
import { getLibrary } from './utils'

interface InjectorProps {
  children?: ReactNode
}

interface RootState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [chainId: number]: ((props: any) => JSX.Element)
}

const web3Roots: RootState = {};
(() => {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in multipleConnector) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    try {
      const root = createWeb3ReactRoot(`MultipleConnector${key}`)
      web3Roots[+key] = root
    } catch (e) {
      // snooze
    }
  }
})()

const InnerContextComponent: React.FC<{ chainId: number }> = ({ chainId, children }) => {
  const {
    active: networkActive, activate: activateNetwork,
  } = useWeb3React<Web3Provider>(`MultipleConnector${chainId}`)

  const network = multipleConnector[chainId]

  useEffect(() => {
    (async () => {
      if (!networkActive) {
        await activateNetwork(network, undefined, true)
      }
    })()
  }, [networkActive, activateNetwork, chainId, network])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  )
}

const MultipleProvider = ({ children }: InjectorProps): JSX.Element => {
  const provider = useMemo(() => Object
    .keys(web3Roots)
    .reduce((baseProvider, key) => {
      const Component = web3Roots[+key]
      if (baseProvider === null) {
        // eslint-disable-next-line react/no-unstable-nested-components
        return (
          <Component getLibrary={getLibrary}>
            <InnerContextComponent chainId={+key}>
              {children}
            </InnerContextComponent>
          </Component>
        )
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      return (
        <Component getLibrary={getLibrary}>
          <InnerContextComponent chainId={+key}>
            {baseProvider}
          </InnerContextComponent>
        </Component>
      )
    }, null as JSX.Element | null), [children])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {provider}
    </>
  )
}

export default MultipleProvider
