import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ModalType {
  CONNECT = 1,
  WALLET = 2,
  BRIDGE_TRANSFER = 3,
  SELECT_BRIDGE_TOKEN = 4,
  SELECT_BRIDGE_NETWORK = 5,
  SELECT_DEX_TOKEN = 6,
  WRONG_NETWORK_MODAL = 7,
  SELECT_DEX_NETWORK = 8,
  CHANGE_NETWORK_MODAL = 9
}

type ModalState = {
  [mType in ModalType]: boolean
}

const generateInitState = () => (
  Object.values(ModalType).filter((mType) => typeof mType !== 'string').reduce((obj, key) => {
    obj[key as unknown as ModalType] = false
    return obj
  }, {} as ModalState)
)

const initialState = generateInitState()

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: () => (
      generateInitState()
    ),
    openModal: (state, action: PayloadAction<ModalType>) => {
      state[action.payload] = true
    },
  },
})

export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
