import { useMemo } from 'react'

import { Block } from '@ethersproject/providers'

import { useWeb3RequestQuery } from '../features/api/web3ApiSlice'
import { HookProvider } from './types'

interface FetchBlockProps {
  provider?: HookProvider
  pollingInterval?: number
}

interface FetchBlockAction {
  data: null | Block
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

const DefaultPollingInterval = 15_000

const useFetchBlock = ({
  provider,
  pollingInterval = DefaultPollingInterval,
}: FetchBlockProps): FetchBlockAction => {
  const { data, ...queryData } = useWeb3RequestQuery({
    provider,
    request: {
      method: 'getBlock',
      params: ['latest', false],
    },
  }, {
    pollingInterval,
    skip: !provider,
  })

  const response = useMemo(() => (!data ? null : data as null | Block), [data])

  return {
    data: response,
    ...queryData,
  }
}

export default useFetchBlock
