export interface Project {
  id: string
  address: string
  name: string
  description: string | null
  symbol: string
  logoUri: string | null
  fundToken: {
    address: string
    chainId: string
    name: string
    symbol: string
    decimals: number
    logoUri: string | null
  }
  tokenAddress: string | null
  chainId: string
  decimals: number
  rate: string
  startTime: number
  endTime: number
  listingTime: number | null
  minSellLimit: string
  maxSellLimit: string
  totalSupply: string
  initialSupply: string
  hardCap: string
  isWhitelisted: boolean
  socials: string[]
  demoUrl: string | null
  // new upd
  vestingDescription: string | null
  tokensSold: string
  isCanceled: boolean
}

export const GET_PROJECTS = (): string => `
  query {
    projects {
      id
      address
      description
      name
      symbol
      logoUri
      fundToken {
        address
        chainId
        name
        symbol
        decimals
        logoUri
      }
      tokenAddress
      decimals
      chainId
      rate
      startTime
      endTime
      listingTime
      hardCap
      minSellLimit
      maxSellLimit
      totalSupply
      initialSupply
      isWhitelisted
      socials
      demoUrl
      vestingDescription
      tokensSold
      isCanceled
    }
  }
`

export interface KYCResponse {
  address: string
  formUrl: string
  isWhitelisted: boolean
  errCode: string | null
  verificationStatus: number | null
}

export interface WalletResponse {
  address: string
  kyc: KYCResponse | null
}

export const GET_WALLET = (address: string): string => `
  query {
    wallet(address: "${address}") {
      address
      kyc {
        address
        formUrl
        isWhitelisted
        errCode
        verificationStatus
      }
    }
  }
`

export const CREATE_OR_UPDATE_KYC = (address: string, signature: string): string => `
  mutation {
    createOrUpdateKyc(address: "${address}", signature: "${signature}") {
      kyc {
        address
        formUrl
        isWhitelisted
        errCode
        verificationStatus
      }
    }
  }
`
