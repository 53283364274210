import { ethers } from 'ethers'
import invariant from 'tiny-invariant'
import { getTokenLogoURI } from '../helpers/utilities'
import { Currency } from './currency'
import { NativeCurrency } from './nativeCurrency'
import { Token } from './token'
import { WETH9 } from './weth9'

/**
 * CKB is the main usage of a 'native' currency, i.e. for Godwoken mainnet and all testnets
 */
export class CKB extends NativeCurrency {
  public constructor(chainId: number) {
    super(chainId, ethers.constants.AddressZero, 18, 'pCKB', 'Godwoken', getTokenLogoURI('pCKB'))
  }

  public get wrapped(): Token {
    const weth9 = WETH9[this.chainId]
    invariant(!!weth9, 'WRAPPED')
    return weth9
  }

  public equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}
