import React from 'react'
import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Fade from '@mui/material/Fade'

const useStyles = makeStyles((theme: Theme) => ({
  wrapped: {
    borderRadius: '8px!important',
  },
  variantSuccess: {
    color: (theme.palette.mode === 'light') ? 'white' : `${theme.palette.secondary.main}!important`,
    backgroundColor: `${theme.palette.primary.main}!important`,
    '& .MuiSvgIcon-root': {
      color: (theme.palette.mode === 'light') ? 'white' : `${theme.palette.secondary.main}!important`,
    },
  },
  variantError: {
    backgroundColor: `${theme.palette.error.main}!important`,
    '& .MuiSvgIcon-root': {
      color: 'white!important',
    },
  },
  variantInfo: {
    backgroundColor: `${theme.palette.info.main}!important`,
    '& .MuiSvgIcon-root': {
      color: 'white!important',
    },
  },
  variantWarning: {
    backgroundColor: `${theme.palette.warning.main}!important`,
    '& .MuiSvgIcon-root': {
      color: 'white!important',
    },
  },
}))

const SnackbarWrappedProvider = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  const classes = useStyles()
  // const notistackRef = React.createRef<SnackbarProvider>()

  // const onAction = useCallback((key: SnackbarKey): SnackbarAction => {
  //   const onClickDismiss = () => {
  //     notistackRef?.current?.closeSnackbar(key)
  //   }

  //   return (
  //     <IconButton onClick={onClickDismiss}>
  //       <CloseIcon />
  //     </IconButton>
  //   )
  // }, [notistackRef])

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={classes.wrapped}
      classes={classes}
      preventDuplicate
      dense
      hideIconVariant
      TransitionComponent={Fade as React.ComponentType}
    // ref={notistackRef}
    // action={onAction}
    >
      {children}
    </SnackbarProvider>
  )
}

export default SnackbarWrappedProvider
