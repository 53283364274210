import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'

import { ethers } from 'ethers'

import { ConnectorNames } from '../../helpers/connectors'
import { createStorage } from '../../helpers/utilities'

interface WalletState {
  chainId: number
  account: string
  loaded: boolean
  isAuthorized: boolean
  // when user manually deauthorize, metamask does not now anything
  // so some simulation should be added
  forcedExit: boolean
  connector: ConnectorNames | null
}

interface AuthorizeData {
  chainId: number
  account: string
}

const initialState: WalletState = {
  chainId: 1,
  account: ethers.constants.AddressZero,
  loaded: false,
  isAuthorized: false,
  forcedExit: false,
  connector: null,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload
    },
    authorize: (state, action: PayloadAction<AuthorizeData>) => {
      state.loaded = true
      state.isAuthorized = true
      state.chainId = action.payload.chainId
      state.account = action.payload.account
    },
    deauthorize: (state, action: PayloadAction<boolean | undefined>) => {
      state.loaded = true
      state.isAuthorized = false
      if (typeof action.payload !== 'undefined') {
        state.forcedExit = action.payload
      }
      state.connector = null
    },
    deforceWallet: (state) => {
      state.forcedExit = false
      state.connector = null
    },
    setConnector: (state, action: PayloadAction<ConnectorNames | null>) => {
      state.connector = action.payload
    },
  },
})

export const {
  setLoaded, authorize, deauthorize, deforceWallet, setConnector,
} = walletSlice.actions

export default persistReducer({
  key: walletSlice.name,
  version: 2,
  storage: createStorage('kirkaDB'),
  whitelist: ['connector'],
}, walletSlice.reducer)
