import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { request, ClientError } from 'graphql-request'
import { DEFAULT_GRAPH_NODE_URI } from '../../helpers/constants'

interface GraphRequest {
  graphNodeURL?: string
  subgraphName?: string
  query: string
}

export const graphNodeApiSlice = createApi({
  reducerPath: 'graphNodeApi',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    graphRequest: builder.query<unknown, GraphRequest>({
      queryFn: async ({ graphNodeURL = DEFAULT_GRAPH_NODE_URI, subgraphName, query }) => {
        try {
          const result = await request(`${graphNodeURL}${subgraphName ? `/subgraphs/name/${subgraphName}` : ''}`, query)
          return { data: result }
        } catch (error) {
          if (error instanceof ClientError) {
            return { error: { status: error.response.status, data: error } }
          }
          return { error: { status: 500, data: error } }
        }
      },
    }),
  }),
})

export const { useGraphRequestQuery, useLazyGraphRequestQuery } = graphNodeApiSlice

export default graphNodeApiSlice
