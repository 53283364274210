import { useMemo } from 'react'

import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { BigNumber } from 'ethers'

import { Currency } from '../../entities'
import { getPriceForCurrency } from '../../helpers/utilities'
import useFetchPriceCurrency from '../../hooks/useFetchPriceCurrency'

interface PriceBoxProps {
  value: BigNumber
  currency: Currency
}

export const PriceBox = ({ value, currency }: PriceBoxProps): JSX.Element => {
  const { price, isLoading, isFetching } = useFetchPriceCurrency(currency)

  const convertedPrice = useMemo(
    () => ((!price) ? 0 : getPriceForCurrency(currency, value, price)),
    [currency, value, price],
  )

  return (
    <div>
      {(isLoading || isFetching) ? (
        <Typography>
          <CircularProgress color="secondary" size={14} />
        </Typography>
      ) : (
        <Typography>{convertedPrice}</Typography>
      )}
    </div>
  )
}
