import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BigNumber } from 'ethers'
import { RootState } from '../../app/store'

import { Currency } from '../../entities'

export enum TokenIndexer {
  TOKEN0 = 'token0',
  TOKEN1 = 'token1',
  LIQUIDITY_TOKEN = 'liquidityToken',
  STAKING = 'staking',
  IDO = 'ido',
}

export interface TokenState {
  currency?: Currency
  selectedAmount?: BigNumber
  maxAmount?: BigNumber
  approved: boolean
}

export interface LiquidityTokenState extends TokenState {
  selectedAmount0?: BigNumber
  selectedAmount1?: BigNumber
}

interface DexState {
  currentIndexer?: TokenIndexer
  updateIndexer?: TokenIndexer
  extraData?: unknown
  [TokenIndexer.TOKEN0]: TokenState
  [TokenIndexer.TOKEN1]: TokenState
  [TokenIndexer.LIQUIDITY_TOKEN]: LiquidityTokenState
  [TokenIndexer.STAKING]: TokenState
  [TokenIndexer.IDO]: TokenState
}

interface UpdateTokenState {
  indexer: TokenIndexer
  currency?: Currency
}

interface UpdateAmountState {
  indexer: TokenIndexer
  amount?: BigNumber
  update?: boolean
  force?: boolean
}

interface UpdateApprovalState {
  indexer: TokenIndexer
  approved: boolean
}

const initialState: DexState = {
  currentIndexer: undefined,
  updateIndexer: undefined,
  extraData: undefined,
  [TokenIndexer.TOKEN0]: {
    currency: undefined,
    selectedAmount: undefined,
    maxAmount: undefined,
    approved: false,
  },
  [TokenIndexer.TOKEN1]: {
    currency: undefined,
    selectedAmount: undefined,
    maxAmount: undefined,
    approved: false,
  },
  [TokenIndexer.STAKING]: {
    currency: undefined,
    selectedAmount: undefined,
    maxAmount: undefined,
    approved: false,
  },
  [TokenIndexer.IDO]: {
    currency: undefined,
    selectedAmount: undefined,
    maxAmount: undefined,
    approved: false,
  },
  [TokenIndexer.LIQUIDITY_TOKEN]: {
    currency: undefined,
    selectedAmount: undefined,
    maxAmount: undefined,
    selectedAmount0: undefined,
    selectedAmount1: undefined,
    approved: false,
  },
}

export const getReversedDexDirection = (indexer?: TokenIndexer): TokenIndexer | undefined => {
  if (!indexer) return undefined
  return (TokenIndexer.TOKEN0 === indexer) ? TokenIndexer.TOKEN1 : TokenIndexer.TOKEN0
}

export const dexSlice = createSlice({
  name: 'dex',
  initialState,
  reducers: {
    setDexIndexer: (state, action: PayloadAction<TokenIndexer | undefined>) => {
      state.currentIndexer = action.payload
    },
    setDexCurrency: (state, action: PayloadAction<UpdateTokenState>) => {
      state[action.payload.indexer].currency = action.payload.currency
    },
    setDexSelectedAmount: (state, action: PayloadAction<UpdateAmountState>) => {
      const tokenState = state[action.payload.indexer] as TokenState
      if (
        action.payload.force
        || (
          tokenState.selectedAmount !== action.payload.amount
        )
        || (
          action.payload.amount && !tokenState.selectedAmount?.eq(action.payload.amount)
        )
      ) {
        tokenState.selectedAmount = action.payload.amount
      }
      if (action.payload.update === true) {
        // const reverseIndexer = getReversedDexDirection(action.payload.indexer)
        // if (reverseIndexer) {
        //   state[reverseIndexer].selectedAmount = undefined
        // }
        state.updateIndexer = action.payload.indexer
      } else {
        state.updateIndexer = undefined
      }
    },
    setDexMaxAmount: (state, action: PayloadAction<UpdateAmountState>) => {
      const tokenState = state[action.payload.indexer] as TokenState
      tokenState.maxAmount = action.payload.amount
    },
    setDexApproval: (state, action: PayloadAction<UpdateApprovalState>) => {
      state[action.payload.indexer].approved = action.payload.approved
    },
    setExtraData: (state, action: PayloadAction<unknown>) => {
      state.extraData = action.payload
    },
    resetDexAmounts: (state) => {
      state[TokenIndexer.TOKEN0].selectedAmount = undefined
      state[TokenIndexer.TOKEN1].selectedAmount = undefined
    },
    resetDexAmount: (state, action: PayloadAction<TokenIndexer>) => {
      state[action.payload].selectedAmount = undefined
    },
    setBaseLiquidityData: (state, action: PayloadAction<{
      currency: Currency,
      maxLiquidityAmount: BigNumber,
    }>) => {
      const tokenState = state[TokenIndexer.LIQUIDITY_TOKEN]
      tokenState.currency = action.payload.currency
      tokenState.maxAmount = action.payload.maxLiquidityAmount
    },
    setUpdateLiquidityData: (state, action: PayloadAction<{
      liquidityAmount?: BigNumber,
      selectedAmount0?: BigNumber,
      selectedAmount1?: BigNumber,
    }>) => {
      const tokenState = state[TokenIndexer.LIQUIDITY_TOKEN]
      tokenState.selectedAmount = action.payload.liquidityAmount
      tokenState.selectedAmount0 = action.payload.selectedAmount0
      tokenState.selectedAmount1 = action.payload.selectedAmount1
    },
  },
})

export const getSelectedDexToken = (indexer: TokenIndexer) => (state: RootState): TokenState | LiquidityTokenState => (
  state.dex[indexer]
)

export const {
  setDexIndexer, setDexCurrency, setDexSelectedAmount, setDexMaxAmount,
  setDexApproval, resetDexAmounts, resetDexAmount, setExtraData,
  setBaseLiquidityData, setUpdateLiquidityData,
} = dexSlice.actions

export default dexSlice.reducer
