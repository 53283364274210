import React, { useState, useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { ethers } from 'ethers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'

import BootstrapDialogTitle from './BootstrapDialogTitle'
import ImgIcon from '../icons/ImgIcon'
import { useFetchDexCurrencies, useFetchBalancePerCurrencies } from '../../hooks'
import { Currency } from '../../entities'
import { getChainData, prettyFormat } from '../../helpers/utilities'
import {
  setDexIndexer, TokenIndexer,
} from '../../features/dex/dexSlice'
import { DEFAULT_TOKEN_SYMBOL } from '../../helpers/constants'

const SearchField = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(2),
  '& fieldset': {
    borderRadius: theme.spacing(1),
  },
}))

const SelectTokenButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

interface BridgeTokenModalProps {
  indexer: TokenIndexer
  account: string
  chainId: number
}

const InnerDexTokenModal = ({
  indexer,
  account,
  chainId,
}: BridgeTokenModalProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('')

  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.SELECT_DEX_TOKEN])

  const chain = getChainData(chainId)
  const { dexCurrencies } = useFetchDexCurrencies(chain?.dex?.tokenLists)
  const navigate = useNavigate()
  const location = useLocation()

  const path = location.pathname
  const [, baseRoute, t0, t1] = path.split('/')

  const token0 = t0 || chain?.dex?.defaultSwapParams?.[0] || ''
  const token1 = t1 || chain?.dex?.defaultSwapParams?.[0] || ''

  const currencies = useMemo(() => {
    const hcurs = dexCurrencies.filter((currency) => currency.chainId === chainId) || []
    return (searchValue)
      ? hcurs.filter((currency) => currency.name.toLowerCase().includes(searchValue.toLowerCase())
        || currency.symbol.toLowerCase().includes(searchValue.toLowerCase())
        || currency.address.toLowerCase() === searchValue.toLowerCase())
      : hcurs
  }, [dexCurrencies, chainId, searchValue])

  const handleClose = useCallback(() => {
    setSearchValue('')
    dispatch(closeModal())
  }, [dispatch, setSearchValue])

  const { library: provider } = useWeb3React<Web3Provider>()

  const { data, isLoading, isFetching } = useFetchBalancePerCurrencies({
    provider,
    fetchInfo: currencies.map((currency) => ({ address: account, currency })),
  })

  const balances = Array.isArray(data) ? data : [data]

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleSelect = (currency: Currency) => () => {
    const routeName = (currency.address === ethers.constants.AddressZero) ? currency.symbol : currency.address
    if (!token0 && indexer === TokenIndexer.TOKEN1) {
      navigate(`/${baseRoute}/${chain?.dex?.defaultSwapParams?.[0] || DEFAULT_TOKEN_SYMBOL}/${routeName}`)
    } else if (indexer === TokenIndexer.TOKEN0) {
      navigate(`/${baseRoute}/${routeName}${(token1) ? `/${token1}` : ''}`)
    } else {
      navigate(`/${baseRoute}/${token0}/${routeName}`)
    }
    dispatch(setDexIndexer(undefined))
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="bridge-token-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="bridge-token-dialog-title" onClose={handleClose}>
        Select a token
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Stack sx={{ width: 400 }}>
          <SearchField
            fullWidth
            type="search"
            variant="outlined"
            placeholder="Search name or paste address"
            onChange={onSearch}
            value={searchValue}
          />
          <List dense sx={{ paddingBottom: 2 }}>
            {!currencies.length ? (
              <ListItem sx={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ marginLeft: 1, fontWeight: 500 }}
                >
                  No result found
                </Typography>
              </ListItem>
            ) : (
              <>
                {currencies.map((currency, index): JSX.Element => {
                  const balance = balances[index]
                  return (
                    <ListItem
                      key={`${currency.chainId}:${currency.address}`}
                      sx={{
                        display: 'flex',
                        padding: 0,
                      }}
                    >
                      <SelectTokenButton
                        fullWidth
                        variant="text"
                        color="secondary"
                        sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
                        size="medium"
                        onClick={handleSelect(currency)}
                        disabled={
                          (indexer === TokenIndexer.TOKEN0
                            && (token0 === currency.symbol || token0 === currency.address))
                          || (indexer === TokenIndexer.TOKEN1
                            && (token1 === currency.symbol || token1 === currency.address))
                        }
                        startIcon={(
                          <Box>
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              textTransform: 'none',
                            }}
                            >
                              <ImgIcon src={currency.logoURI} />
                              <Typography
                                variant="body2"
                                sx={{ marginLeft: 1, fontWeight: 500 }}
                              >
                                {currency.symbol}
                              </Typography>
                            </Box>
                            <Typography sx={() => ({ marginLeft: 4, fontSize: 8, color: '#ffffff' })}>
                              {currency.name}
                            </Typography>
                          </Box>
                        )}
                      >
                        <Box>
                          {(isLoading || isFetching) ? (
                            <CircularProgress size={14} color="secondary" />
                          ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                              {balance ? prettyFormat(balance, currency.decimals) : 0}
                            </>
                          )}
                        </Box>
                      </SelectTokenButton>
                    </ListItem>
                  )
                })}
              </>
            )}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const DexTokenModal = (): JSX.Element | null => {
  const {
    account, library: provider, active, chainId,
  } = useWeb3React<Web3Provider>()
  const open = useAppSelector((state) => state.modal[ModalType.SELECT_DEX_TOKEN])
  const indexer = useAppSelector((state) => state.dex.currentIndexer)

  if (active && chainId && provider && account && indexer && open) {
    return <InnerDexTokenModal indexer={indexer} account={account} chainId={chainId} />
  }
  return null
}

export default DexTokenModal
