import { useCallback, useMemo } from 'react'
import { ethers } from 'ethers'
import { TokenLink, BridgeToken, Currency } from '../entities'
import { useFetchTokensQuery } from '../features/api/tokenListApiSlice'
import { DEFAULT_TOKEN_SYMBOL, BRIDGE_TOKEN_LIST_BASE_URI } from '../helpers/constants'
import { getNativeCurrency, matchToken } from './utils'

interface BridgeCurrencyAction {
  getDefaultCurrency(
    chainId: number,
  ): Currency | undefined

  getCurrency(
    chainId: number,
    addressOrSymbol?: string,
  ): Currency | undefined

  getLinkCurrency(
    sourceChainId: number,
    targetChainId: number,
    addressOrSymbol?: string,
  ): Currency | undefined

  bridgeCurrencies: Currency[]

  isLoading: boolean
}

const useFetchBridgeCurrencies = (): BridgeCurrencyAction => {
  const { data: tokens = [], isLoading } = useFetchTokensQuery([
    `${BRIDGE_TOKEN_LIST_BASE_URI}/kirkaswap.token-list.json`,
    `${BRIDGE_TOKEN_LIST_BASE_URI}/kirkaswap-experimental.token-list.json`,
  ])

  const bridgeCurrencies = useMemo((): Currency[] => {
    if (isLoading) return []

    const currencies: BridgeToken[] = []

    const updateOrCreateToken = (bridgeToken: BridgeToken) => {
      const index = currencies.findIndex((currency) => matchToken(currency, bridgeToken.address, bridgeToken.chainId))
      if (index === -1) {
        currencies.push(bridgeToken)
      }
    }

    function getFromCache(chainId: number, address: string): BridgeToken | undefined {
      const crs = currencies.filter((currency) => matchToken(currency, address, chainId))
      if (crs.length) {
        return crs[0]
      }
      return undefined
    }

    tokens.forEach((token) => {
      const bridgeInfo = token.extensions?.bridgeInfo as unknown as {
        [chainId: string]: {
          tokenAddress: string,
          destBridgeAddress: string,
          originBridgeAddress: string
        }
      } | undefined
      if (!bridgeInfo) return

      const sourceToken = getFromCache(token.chainId, token.address) || new BridgeToken(
        token.chainId,
        token.address,
        token.decimals,
        token.symbol,
        token.name,
        token.logoURI,
      )
      Object.keys(bridgeInfo).forEach((chainId) => {
        const targetTokenLink: TokenLink = bridgeInfo[chainId]
        if (targetTokenLink.tokenAddress === ethers.constants.AddressZero) {
          const nativeToken: Currency | undefined = getNativeCurrency(+chainId)
          if (!nativeToken) {
            console.log(`Native token for chain "${chainId}" not found`)
            return
          }
          const sourceTokenLink: TokenLink = {
            tokenAddress: sourceToken.address,
            originBridgeAddress: targetTokenLink.destBridgeAddress,
            destBridgeAddress: targetTokenLink.originBridgeAddress,
          }
          const targetToken = getFromCache(nativeToken.chainId, nativeToken.address) || new BridgeToken(
            nativeToken.chainId,
            nativeToken.address,
            nativeToken.decimals,
            nativeToken.symbol,
            nativeToken.name,
            nativeToken.logoURI,
          )
          targetToken.addLink(sourceToken.chainId, sourceTokenLink)
          updateOrCreateToken(targetToken)
        }
        sourceToken.addLink(+chainId, targetTokenLink)
      })
      updateOrCreateToken(sourceToken)
    })
    return currencies
  }, [tokens, isLoading])

  const getCurrency = useCallback((
    chainId: number,
    addressOrSymbol: string = ethers.constants.AddressZero,
  ): Currency | undefined => bridgeCurrencies.filter(
    (token) => matchToken(token, addressOrSymbol, chainId),
  )[0], [bridgeCurrencies])

  const getLinkCurrency = useCallback((
    sourceChainId: number,
    targetChainId: number,
    addressOrSymbol: string = ethers.constants.AddressZero,
  ): Currency | undefined => {
    const sourceToken = getCurrency(sourceChainId, addressOrSymbol)
    if (!sourceToken) return undefined

    const link = sourceToken?.getBridged(targetChainId)
    if (!link) return undefined

    return getCurrency(targetChainId, link.tokenAddress)
  }, [getCurrency])

  const getDefaultCurrency = useCallback((
    chainId: number,
  ): Currency | undefined => bridgeCurrencies.filter(
    (currency) => currency.chainId === chainId && (
      currency.symbol.includes(DEFAULT_TOKEN_SYMBOL)
    ),
  )[0], [bridgeCurrencies])

  return {
    getDefaultCurrency,
    getCurrency,
    getLinkCurrency,
    bridgeCurrencies,
    isLoading,
  }
}

export default useFetchBridgeCurrencies
