import React, { useCallback, useMemo } from 'react'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'

import { ethers } from 'ethers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'

import BootstrapDialogTitle from './BootstrapDialogTitle'
import ImgIcon from '../icons/ImgIcon'
import {
  BridgeDirectionName,
  selectDirection,
  setDualDirection,
  swapDirection,
} from '../../features/bridge/bridgeSlice'
import { supportedChains } from '../../helpers/chains'
import { ChainData } from '../../helpers/types'
import { useFetchBridgeCurrencies } from '../../hooks'
import { getBridgeSettings } from '../../features/settings/settingsSlice'

const BridgeChainSelectModal = (): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.SELECT_BRIDGE_NETWORK])
  const selected = useAppSelector((state) => state.bridge.selected)
  const bridgeSettings = useAppSelector(getBridgeSettings)
  const directions = useAppSelector((state) => state.bridge.directions)
  const reverseSelected = selected === BridgeDirectionName.SOURCE
    ? BridgeDirectionName.TARGET
    : BridgeDirectionName.SOURCE
  const reverseDirection = useAppSelector((state) => state.bridge.directions[reverseSelected])
  const {
    getCurrency, getLinkCurrency, getDefaultCurrency, isLoading,
  } = useFetchBridgeCurrencies()

  const handleClose = useCallback(() => {
    dispatch(selectDirection())
    dispatch(closeModal())
  }, [dispatch])

  const chains = useMemo(
    () => (!isLoading ? supportedChains.filter(
      (chain) => (
        (bridgeSettings.useExperimental || !chain.isTest)
        && chain.bridgeAddress !== ethers.constants.AddressZero
      ),
    ) : []),
    [bridgeSettings.useExperimental, isLoading],
  )

  if (!selected) return null

  const currentDirection = directions[selected]
  if (
    typeof currentDirection.currency === 'undefined'
    || typeof reverseDirection.currency === 'undefined'
  ) return null

  const handleSelect = (chain: ChainData) => () => {
    if (chain.chainId === reverseDirection.chainId) {
      dispatch(swapDirection())
    } else if (reverseDirection.chainId && chain.chainId !== currentDirection.chainId) {
      console.group('Switching chain currencies')
      // Take the same currency symbol from new chain
      let nominatedCurrency = getCurrency(chain.chainId, currentDirection.currency?.symbol)
      // if not found, then take a default for this chain
      if (!nominatedCurrency) {
        nominatedCurrency = getDefaultCurrency(chain.chainId)
        // NOTE: Should not occur, but in case, send error to sentry
        if (!nominatedCurrency) {
          throw new Error(`Currency for chain "${chain.chainId}" not set`)
        }
        console.log('Nominated selected as native')
      } else {
        console.log('Nominated token found')
      }
      // as we know current direction currency, let's find it is bridge equivalent
      let derivedCurrency = getLinkCurrency(
        chain.chainId,
        reverseDirection.chainId,
        currentDirection.currency?.symbol,
      )

      let derivedChainId = reverseDirection.chainId
      // if it is occured then let's fallback to another chain where it exists
      // to prevent errors
      // TODO: Maybe show wrong bridge direction to prevent confusion?
      // TODO: Maybe add snackbar msg that bridge does not have an equivalent?
      if (!derivedCurrency) {
        console.log('Derived chain not found')
        if (!nominatedCurrency.isNative) {
          // force set dfault currncy on nominator
          nominatedCurrency = getDefaultCurrency(chain.chainId)
          // NOTE: Should not occur, but in case, send error to sentry
          if (!nominatedCurrency) {
            throw new Error(`Currency for chain "${chain.chainId}" not set`)
          }
          console.log('Taking default currency for derived')
        } else {
          console.log('Keep the same nominated currency')
        }

        derivedChainId = chain.defaultTargetChainId
        derivedCurrency = getDefaultCurrency(derivedChainId)
        // Impossible, only if error in chain config
        if (!derivedCurrency) {
          throw new Error(`Currency for chain "${derivedChainId}" not set`)
        }
      } else {
        console.log('Derived chain found')
      }
      dispatch(setDualDirection({
        [selected]: {
          chainId: chain.chainId,
          currency: nominatedCurrency,
        },
        [reverseSelected]: {
          chainId: derivedChainId,
          currency: derivedCurrency,
        },
      }))
      console.groupEnd()
    }
    handleClose()
  }

  const buttonEnabled = (chain: ChainData) => {
    // matching chains always disabled as current selected for source
    if (selected === BridgeDirectionName.SOURCE && currentDirection.chainId === chain.chainId) {
      return false
    }
    // special for target - it should disable direction where no token on it
    if (selected === BridgeDirectionName.TARGET && !reverseDirection.currency?.getBridged(chain.chainId)) {
      return false
    }
    return true
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="bridge-network-select-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="bridge-network-select-dialog-title" onClose={handleClose}>
        {`Select ${selected === BridgeDirectionName.SOURCE ? 'source' : 'target'} network`}
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Stack sx={{ width: 400, padding: 1 }}>
          <List dense sx={{ paddingBottom: 2 }}>
            {chains.map((chain) => (
              <ListItem key={chain.chainId} sx={{ padding: 0 }}>
                <Button
                  fullWidth
                  variant="text"
                  color="secondary"
                  sx={(theme) => ({
                    justifyContent: 'space-between',
                    marginLeft: theme.spacing(2),
                    marginRight: theme.spacing(2),
                    textTransform: 'none',
                  })}
                  size="medium"
                  disabled={!buttonEnabled(chain)}
                  onClick={handleSelect(chain)}
                  endIcon={(
                    <ImgIcon src={chain.nativeCurrency.logoURI} />
                  )}
                >
                  {chain.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default BridgeChainSelectModal
