import { useState } from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded'
import CircularProgress from '@mui/material/CircularProgress'

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import baseRoutes from '../../routes'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  ConnectButton, RouteButton, LogoButton,
} from '../buttons'
import { getChainData, getShortAddress } from '../../helpers/utilities'
import { ModalType, openModal } from '../../features/modal/modalSlice'
import { getUnfinishedTxCount } from '../../features/wallet/transactionSlice'

const Navigation = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const { error } = useWeb3React<Web3Provider>()
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  const unfinishedTxCount = useAppSelector(getUnfinishedTxCount)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => (
    setAnchorElNav(event.currentTarget)
  )
  const handleCloseNavMenu = () => setAnchorElNav(null)

  const chainData = getChainData(wallet.chainId)

  const openUnsupportedModal = () => dispatch(openModal(ModalType.WRONG_NETWORK_MODAL))
  const openChangeNetworkModal = () => dispatch(openModal(ModalType.CHANGE_NETWORK_MODAL))
  const openWalletModal = () => dispatch(openModal(ModalType.WALLET))

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="navigation"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {baseRoutes.map((route): JSX.Element | null => (
                  <RouteButton
                    key={route.name}
                    onClick={handleCloseNavMenu}
                    name={route.name}
                    params={route.params}
                    path={route.path}
                    disabled={route.disabled}
                  />
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <LogoButton />
              {baseRoutes.map((route): JSX.Element | null => (
                <RouteButton
                  key={route.name}
                  onClick={handleCloseNavMenu}
                  name={route.name}
                  params={route.params}
                  path={route.path}
                  disabled={route.disabled}
                />
              ))}
            </Box>
            <ButtonGroup
              variant="contained"
              color="secondary"
              aria-label="contained button group"
              sx={{ '& > button': { textTransform: 'none' } }}
            >
              {wallet.loaded && isUnsupportedChainIdError && (
                <Button
                  startIcon={<AltRouteRoundedIcon />}
                  color="error"
                  onClick={openUnsupportedModal}
                >
                  Wrong network
                </Button>
              )}
              {
                wallet.loaded && wallet.isAuthorized && !isUnsupportedChainIdError && (
                  <>
                    <Button onClick={openChangeNetworkModal}>{chainData?.name}</Button>
                    {unfinishedTxCount ? (
                      <Button onClick={openWalletModal} color="warning" sx={{ color: 'black' }}>
                        {`${unfinishedTxCount} Pending`}
                        <CircularProgress size={14} sx={{ marginLeft: 1, color: 'black' }} />
                      </Button>
                    ) : (
                      <Button onClick={openWalletModal}>
                        {getShortAddress(wallet.account)}
                      </Button>
                    )}
                  </>
                )
              }
              {wallet.loaded && !wallet.isAuthorized && !isUnsupportedChainIdError && (
                <ConnectButton color="secondary" />
              )}
              {/* <ThemeButton /> */}
            </ButtonGroup>
          </Toolbar>
        </Container>
      </AppBar>
      {/* Required for fixed navigation */}
      <Box sx={(theme) => ({ ...theme.mixins.toolbar })} />
    </Box>
  )
}

export default Navigation
