import React, { useState, useCallback, useMemo } from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { ethers } from 'ethers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'

import BootstrapDialogTitle from './BootstrapDialogTitle'
import ImgIcon from '../icons/ImgIcon'
import {
  BridgeDirectionName, selectDirection, setDualDirection,
} from '../../features/bridge/bridgeSlice'
import { useFetchBridgeCurrencies, useFetchBalancePerCurrencies } from '../../hooks'
import { Currency } from '../../entities'
import { prettyFormat } from '../../helpers/utilities'

const SearchField = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(2),
  '& fieldset': {
    borderRadius: theme.spacing(1),
  },
}))

const SelectTokenButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

interface BridgeTokenModalProps {
  provider: Web3Provider
  account: string
  direction: BridgeDirectionName
}

const InnerBridgeTokenModal = ({
  provider,
  account,
  direction,
}: BridgeTokenModalProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('')

  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.SELECT_BRIDGE_TOKEN])
  const bridgeDirections = useAppSelector((state) => state.bridge.directions)

  const { bridgeCurrencies, getLinkCurrency } = useFetchBridgeCurrencies()

  const bridgeDirection = bridgeDirections[direction]

  const currencies = useMemo(() => {
    const hcurs = bridgeCurrencies.filter((currency) => currency.chainId === bridgeDirection.chainId) || []
    return (searchValue)
      ? hcurs.filter((currency) => currency.name.toLowerCase().includes(searchValue.toLowerCase())
        || currency.symbol.toLowerCase().includes(searchValue.toLowerCase())
        || currency.address.toLowerCase() === searchValue.toLowerCase())
      : hcurs
  }, [bridgeCurrencies, bridgeDirection.chainId, searchValue])

  const reverse = (direction === BridgeDirectionName.SOURCE)
    ? BridgeDirectionName.TARGET
    : BridgeDirectionName.SOURCE

  const reverseDirection = useAppSelector(
    (state) => state.bridge.directions[reverse],
  )

  const handleClose = useCallback(() => {
    setSearchValue('')
    dispatch(selectDirection())
    dispatch(closeModal())
  }, [dispatch, setSearchValue])

  const { data, isLoading, isFetching } = useFetchBalancePerCurrencies({
    provider,
    fetchInfo: currencies.map((currency) => ({ address: account, currency })),
  })

  const balances = Array.isArray(data) ? data : [data]

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleSelect = (currency: Currency) => () => {
    if (!reverseDirection.chainId) return
    const reverseCurrency = getLinkCurrency(currency.chainId, reverseDirection.chainId, currency.address)
    dispatch(setDualDirection({
      [direction]: {
        currency,
        value: ethers.BigNumber.from(0),
      },
      [reverse]: {
        currency: reverseCurrency,
        value: ethers.BigNumber.from(0),
      },
    }))
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="bridge-token-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="bridge-token-dialog-title" onClose={handleClose}>
        Select a token
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Stack sx={{ width: 400 }}>
          <SearchField
            fullWidth
            type="search"
            variant="outlined"
            placeholder="Search name or paste address"
            onChange={onSearch}
            value={searchValue}
          />
          <List dense sx={{ paddingBottom: 2 }}>
            {!currencies.length ? (
              <ListItem sx={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{ marginLeft: 1, fontWeight: 500 }}
                >
                  No result found
                </Typography>
              </ListItem>
            ) : (
              <>
                {currencies.map((currency, index): JSX.Element => {
                  const balance = balances[index]
                  return (
                    <ListItem key={`${currency.chainId}:${currency.address}`} sx={{ padding: 0 }}>
                      <SelectTokenButton
                        fullWidth
                        variant="text"
                        color="secondary"
                        sx={{ justifyContent: 'space-between' }}
                        size="medium"
                        onClick={handleSelect(currency)}
                        startIcon={(
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textTransform: 'none',
                          }}
                          >
                            <ImgIcon src={currency.logoURI} />
                            <Typography
                              variant="body2"
                              sx={{ marginLeft: 1, fontWeight: 500 }}
                            >
                              {currency.symbol}
                            </Typography>
                          </Box>
                        )}
                      >
                        {(isLoading || isFetching) ? (
                          <CircularProgress size={14} color="secondary" />
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {balance ? prettyFormat(balance, currency.decimals) : 0}
                          </>
                        )}
                      </SelectTokenButton>
                    </ListItem>
                  )
                })}
              </>
            )}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const BridgeTokenModal = (): JSX.Element | null => {
  const direction = useAppSelector((state) => state.bridge.selected)

  const { account } = useWeb3React<Web3Provider>()
  const { library: provider, active, chainId } = useWeb3React<Web3Provider>(direction)
  const open = useAppSelector((state) => state.modal[ModalType.SELECT_BRIDGE_TOKEN])

  if (active && chainId && direction && provider && account && open) {
    return <InnerBridgeTokenModal direction={direction} provider={provider} account={account} />
  }
  return null
}

export default BridgeTokenModal
