import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, responsiveFontSizes } from '@mui/material'
import Container from '@mui/material/Container'

import { useAppSelector } from './app/hooks'
import baseRoutes from './routes'
import { Navigation } from './components/pages'
import MetaMaskProvider from './providers/MetaMaskProvider'
import BridgeProvider from './providers/BridgeProvider'
import ModalBaseline from './providers/ModalBaseline'
import RefetcherProvider from './providers/RefetcherProvider'
import SnackbarWrappedProvider from './providers/SnackbarWrappedProvider'
import { DarkThemeOpts, LightThemeOpts } from './theme'
import { getThemeSettings } from './features/settings/settingsSlice'

import './App.css'
import MultipleProvider from './providers/MultipleProvider'

const App = (): JSX.Element => {
  const themeSettings = useAppSelector(getThemeSettings)
  const theme = responsiveFontSizes(createTheme((themeSettings.isDark) ? DarkThemeOpts : LightThemeOpts))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarWrappedProvider>
        <BridgeProvider>
          <Navigation />
          <MetaMaskProvider>
            <MultipleProvider>
              <RefetcherProvider />
              <Routes>
                {baseRoutes.map((route) => {
                  const Component = route.component
                  if (Component !== null) {
                    if (route.nested) {
                      return (
                        <Route
                          key={route.name}
                          path={route.path}
                          element={(
                            <Container
                              disableGutters
                              maxWidth={false}
                            >
                              <Component />
                            </Container>
                          )}
                        >
                          {route.nested.map((nestedRoute) => {
                            const NestedComponent = nestedRoute.component
                            if (NestedComponent !== null) {
                              return (
                                <Route
                                  key={nestedRoute.name}
                                  path={nestedRoute.path}
                                  element={(
                                    <Container
                                      disableGutters
                                      maxWidth={false}
                                    >
                                      <NestedComponent />
                                    </Container>
                                  )}
                                />
                              )
                            }
                            return null
                          })}
                        </Route>
                      )
                    }
                    return (
                      <Route
                        key={route.name}
                        path={route.path}
                        element={(
                          <Container
                            disableGutters
                            maxWidth={false}
                          >
                            <Component />
                          </Container>
                        )}
                      />
                    )
                  }
                  return null
                })}
              </Routes>
              <ModalBaseline />
            </MultipleProvider>
          </MetaMaskProvider>
        </BridgeProvider>
      </SnackbarWrappedProvider>
    </ThemeProvider>
  )
}

export default App
