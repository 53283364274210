import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import { v4 as uuidv4 } from 'uuid'
import { BigNumber, constants } from 'ethers'

import { Currency } from '../../entities'
import { RootState } from '../../app/store'
import { createStorage, getNowTs } from '../../helpers/utilities'

interface PoolData {
  // wallet address
  account: string
  // LP token address
  pairAddress: string
  // current pooled amount
  lpPooled: BigNumber
  // total pooled amount
  lpTotal: BigNumber
  // own share
  lpShare: number
  // currency for A
  currency0: Currency
  // current amount in A
  pooled0: BigNumber
  // currency for B
  currency1: Currency
  // current amount in B
  pooled1: BigNumber
}

export interface PoolInfo extends PoolData {
  // local id
  id: string
  // time of create
  createdAt: number
  // time of update
  updatedAt: number
}

interface LiquidityState {
  pools: Array<PoolInfo>
}

const initialState: LiquidityState = {
  pools: [],
}

const generateInitTxPayload = () => ({
  id: uuidv4(),
  createdAt: getNowTs(),
  updatedAt: getNowTs(),
})

export const liquiditySlice = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    createOrUpdatePool: (state, action: PayloadAction<PoolData>) => {
      if (
        action.payload.account === constants.AddressZero
      ) return

      const index = state.pools
        .findIndex((pool) => (
          pool.currency0.address === action.payload.currency0.address
          && pool.currency1.address === action.payload.currency1.address
          && pool.account === action.payload.account
        ))
      if (index !== -1) {
        const found = state.pools[index]
        // no need to update as info same
        if (
          BigNumber.from(found.pooled0).eq(action.payload.pooled0)
          && BigNumber.from(found.pooled1).eq(action.payload.pooled1)
        ) return

        state.pools[index] = {
          ...found,
          ...action.payload,
          updatedAt: getNowTs(),
        }
      } else {
        state.pools.push({
          ...generateInitTxPayload(),
          ...action.payload,
        })
      }
    },
    deletePool: (state, action: PayloadAction<{
      account: string,
      pairAddress: string
    }>) => {
      const poolInfo = state.pools
        .find((pool) => (
          pool.pairAddress === action.payload.pairAddress
          && pool.account === action.payload.account
        ))
      if (poolInfo) {
        state.pools = state.pools.filter((pool) => pool.id !== poolInfo.id)
      }
    },
  },
})

export const getAccountPools = (state: RootState): PoolInfo[] => (
  state.liquidity.pools
    .slice(0)
    .sort((pool1, pool2) => pool2.createdAt - pool1.createdAt)
    .filter((pool) => pool.account === state.wallet.account && pool.currency0.chainId === state.wallet.chainId)
)

export const getPoolInfo = (pairAddress?: string) => (state: RootState): PoolInfo | undefined => (
  (pairAddress)
    ? state.liquidity.pools.find(
      (pool) => (
        pool.pairAddress === pairAddress
        && pool.account === state.wallet.account
        && pool.currency0.chainId === state.wallet.chainId
      ),
    )
    : undefined
)

export const getPoolInfoByCurrencies = (
  currency0?: Currency,
  currency1?: Currency,
) => (state: RootState): PoolInfo | undefined => (
  currency0 && currency1 && state.liquidity.pools.find((pool) => (
    (
      pool.account === state.wallet.account
      && pool.currency0.chainId === state.wallet.chainId
    ) && (
      (
        pool.currency0.address === currency0.address
        && pool.currency1.address === currency1.address
      )
      || (
        pool.currency1.address === currency0.address
        && pool.currency0.address === currency1.address
      )
    )
  ))
)

export const {
  createOrUpdatePool,
  deletePool,
} = liquiditySlice.actions

export default persistReducer({
  key: liquiditySlice.name,
  version: 1,
  storage: createStorage('kirkaDB'),
}, liquiditySlice.reducer)
