import { styled } from '@mui/material/styles'
import { BasePaper } from '.'
import { radialConstructor } from './utils'

const FarmInfoPaper = styled(BasePaper)(({ theme }) => ({
  textAlign: 'left',
  background: (theme.palette.mode === 'light')
    ? radialConstructor('#ffffff', theme.palette.primary.main)
    : radialConstructor(theme.palette.primary.light, theme.palette.primary.dark),
}))

export default FarmInfoPaper
