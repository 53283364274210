import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'

export const ColorizedButton = styled(LoadingButton)(({ theme }) => ({
  color: (theme.palette.mode === 'light') ? 'primary' : 'white',
  borderColor: (theme.palette.mode === 'light') ? 'primary' : 'white',
  '&:hover': {
    backgroundColor: (theme.palette.mode === 'light') ? 'primary' : '#ffffff14',
  },
  '& > .MuiButton-endIcon': {
    marginLeft: 0,
  },
}))
