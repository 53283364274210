import { Currency } from '../entities'
import { supportedChains } from '../helpers/chains'

export const getNativeCurrency = (chainId: number): Currency => supportedChains.filter(
  (chain) => chainId === chain.chainId,
).map((chain) => chain.nativeCurrency)[0]

const filterByToken = (currency: Currency, addressOrSymbol: string) => (
  (addressOrSymbol.startsWith('0x') && addressOrSymbol.length === 42)
    ? currency.address.toLowerCase() === addressOrSymbol.toLowerCase()
    : currency.symbol === addressOrSymbol
)

export const matchToken = (
  currency: Currency,
  addressOrSymbol: string,
  chainId?: number,
): boolean => {
  if (!chainId) {
    return filterByToken(currency, addressOrSymbol)
  }
  return (
    currency.chainId === chainId
    && filterByToken(currency, addressOrSymbol)
  )
}
