import React, { memo } from 'react'

import { styled, SxProps, Theme } from '@mui/material/styles'
import { Icon } from '@mui/material'

const Img = styled('img')(() => ({
  display: 'flex',
  height: 'inherit',
  width: 'inherit',
}))

export interface ImgIconProps {
  src?: string
  alt?: string
  height?: number | string
  width?: number | string
  sx?: SxProps<Theme>
}

const ImgIcon = memo(({
  width, height, alt = 'icon', ...restImgProps
}: ImgIconProps): JSX.Element => {
  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/empty.svg'
  }
  return (
    <Icon sx={{
      width,
      height,
    }}
    >
      <Img alt={alt} onError={onError} {...restImgProps} />
    </Icon>
  )
})

ImgIcon.displayName = 'ImgIcon'

export default ImgIcon
